import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Alert } from "react-bootstrap";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { handleLogin } from "../services/auth";

const LoginPage = ({ location }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let flashMessage = null;
  if (typeof window !== `undefined`) {
    flashMessage = location.state.message;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(email, password);
  };

  return (
    <Layout>
      <Container
        fluid="md"
        className="d-flex flex-row justify-content-center align-items-center"
        style={{ height: "90vh" }}
      >
        <Row className="flex-column align-items-center w-100">
          <Col lg="4">
            <h1 className="text-center">Login</h1>
            <div style={{ borderBottom: "solid white 2px" }} className="pt-3"></div>
            {!!flashMessage ? <Alert variant="success">{flashMessage}</Alert> : ""}
          </Col>
          <Col lg="4" className="mt-3">
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Login
              </Button>
              <Button variant="link" type="submit">
                <Link to="/signup">Sign up</Link>
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default LoginPage;
